/*
 * Around | Multipurpose Bootstrap Template
 * Copyright 2021 Createx Studio
 * Theme styles
 */
 
// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'mixins';
@import 'utilities';

// Bootstrap
@import '../../dist/vendor/bootstrap/scss/bootstrap';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");


// Layout & components
@import 'reboot';
@import 'components';

// User custom styles
@import 'user';
